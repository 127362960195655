.sidebar__icon .hambergur-slice {
  fill: var(--label-text-color);
}
.sidebar__icon .hambergur-rect {
  fill: none;
}

.sidebar__icon {
  color: white;
  cursor: pointer;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}

.hambergur {
  color: white;
  cursor: pointer;
  margin-bottom: 61px;
}
.sidebar__icon path {
  fill: var(--label-text-color);
  padding-left: 4.5rem;
}
.sidebar__icon--highlight path {
  fill: var(--regular-button-color);
}
.sidebar__icon--highlight span {
  color: var(--regular-button-color);
  padding-left: 4.5rem;
  font-size: 1.3rem;
}
.sidebar__icon span {
  padding-left: 4.5rem;
  font-size: 1.3rem;
}
.sidebar__icon svg {
  position: absolute;
  left: 15px;
}
.sidebar__icon__hambergur {
  width: 1rem;
}
.sidebar {
  /* height: 16rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: rgb(5, 5, 5);
  /* justify-content: space-around; */
}
.sidebar--expand {
  width: 250px;
  left: 0;
  position: absolute;
  background-color: rbg(5, 5, 5);
  z-index: 3;
}
.sidebar__icon__hambergur {
  margin-top: 1rem;
  width: 3rem;
  margin-bottom: 1.7rem;
  cursor: pointer;
}
.sidebar__icon__close-icon {
  margin-top: 2rem;
  width: 1.5rem;
  margin-bottom: 2.2rem;
  align-self: flex-start;
  margin-left: 1.4rem;
  cursor: pointer;
}
@media (max-width: 600px) {
  .sidebar__icon__hambergur {
    margin-top: 0.5rem;
    width: 2.5rem;
    margin-bottom: 1.1rem;
  }

  .sidebar__icon svg {
    left: 9px;
    width: 2rem;
  }
  .sidebar__icon__close-icon {
    margin-bottom: 1.6rem;
    margin-left: 0.7rem;
    margin-top: 1rem;
  }
  .sidebar--expand {
    width: 240px;
  }
  .sidebar__icon span {
    padding-left: 3.5rem;
    font-size: 1rem;
  }
  .sidebar__icon path {
    width: 2.5rem;
  }
  .home-page__sidebar {
    width: 50px;
  }

  .sidebar__icon svg {
    left: 8px;
    width: 2rem;
  }
  .sidebar__icon span {
    padding-left: 3.5rem;
    font-size: 1rem;
  }
  .sidebar__icon {
    margin-bottom: 1rem;
  }
}
