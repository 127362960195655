.login-page {
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background-image: linear-gradient(rgba(51, 46, 46, 0.5), rgba(31, 30, 30, 0.5)),
    url(../../../assets//images/components/background-image.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  justify-content: center;
}

.login-page__welcome-title {
  text-align: center;
  font-family: "ubuntu-regular";
  font-size: 40px;
  margin-top: 100px;
}

.login-page__niurix-logo {
  width: 15rem;
  margin: 0 auto;
}

.login-page__welcome-title {
  color: var(--label-text-color);
}
/* input:-webkit-autofill {
  color: #2a2a2a !important;
} */
/* @media screen(max-width:600px) {
  .login-page__welcome-title {
    font-size: 28px;
  }
  .login-page__niurix-logo {
    margin-top: 83px;
    width: 166px;
  }
} */
@media (max-width: 600px) {
  .login-page__welcome-title {
    font-size: 1.3rem;
    margin-top: 4rem;
  }
  .login-page__niurix-logo {
    width: 200px;
    margin: 0 auto;
    /*! height: 60px; */
  }
  .login-form {
    width: 21rem;
    padding: 2.5rem 1.5rem;
  }
  .login-info {
    font-size: 15px;
  }
  .login-form__login-button {
    width: 75px;
  }
  .login-form__user-input {
    font-size: 1rem;
  }
}
