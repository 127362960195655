.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 100%;
  width: 95%;
  margin: auto;
}
.header__niurix-logo {
  width: 8rem;
}

.admin-logout-button {
  padding: 0.15rem 0.5rem;
  display: flex;
  border-radius: 19px;
  background-color: #505050;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  width: 10rem;
  /* position: fixed; */
}
.header__logout-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.user-settings {
  position: absolute;
  top: 2rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  background-color: #282a2e;
  padding-top: 0.5rem;
  padding-bottom: 0;
  z-index: 2;
}
.settings-button {
  cursor: pointer;
  margin-bottom: 0.9rem;
  background-color: #282a2e;
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  width: 100%;
}
.user-profile-icon {
  margin-right: 0.5rem;
}
@media (max-width: 600px) {
  .admin-logout-button {
    padding: 0.15rem 0.5rem;
    display: flex;
    border-radius: 19px;
    background-color: #505050;
    width: 7rem;
    /* position: fixed; */
  }
  .home-page__header {
    width: calc(100% - 50px);
    position: absolute;
    right: 0;
    height: 50px;
  }
  .header__niurix-logo {
    width: 6rem;
  }
  .admin-logout-button {
    width: 7rem;
    /* position: fixed; */
  }
  .user-profile-icon {
    width: 1.1rem;
  }
  .admin-logout-button {
    font-size: 0.8rem;
    /* position: fixed; */
  }
  .user-settings {
    width: 10rem;
    top: 1.5rem;
    right: 0.3rem;
  }
}
