:root {
  --form-background-color: #101010;
  --navbar-button-color: #1e65e0;
  --regular-button-color: #3693ff;
  --label-text-color: #b0b0b0;
  --label-background-color: #393939;
  --normal-font-color: white;
  --modal-background-color: #191919;
  --add-functionality-button-border: #1e65e0;
  --modal-border-radius: 0.8rem;
}
@font-face {
  font-family: "ubuntu-regular";
  src: url("./assets/fonts/Ubuntu/Ubuntu-Regular.ttf");
}
@font-face {
  font-family: "ubuntu-bold";
  src: url("./assets/fonts/Ubuntu/Ubuntu-Bold.ttf");
}
@font-face {
  font-family: "ubuntu-italic";
  src: url("./assets/fonts/Ubuntu/Ubuntu-Italic.ttf");
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  outline: none;
}
.font-color {
  color: var(--normal-font-color);
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--normal-font-color);
  box-shadow: 0 0 0px 1000 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
} */
@media (max-width: 600px) {
  body {
    font-size: 0.8rem;
  }
}
