.modal-container {
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  background-color: #000000cc;
  font-family: var(--regular-font);
}

.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 14px;
  cursor: pointer;
}

#main-modal .modal-content {
  width: 33rem;
  background-color: var(--modal-background-color);
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: var(--modal-border-radius);
}

#main-modal__title {
  font-size: 1.1rem;
}
#main-modal .modal-header {
  width: 100%;
  display: flex;
}
#main-modal .modal-form__input-section-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 10.5rem;
  cursor: pointer;
}
#main-modal .modal-form__input-section--address {
  width: 100%;
}
#main-modal .modal-form__input-section--date {
  width: 50%;
}
.modal-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
}
.modal-form__footer {
  display: flex;
  justify-content: flex-end;
  width: 98%;
}
.modal-form__sumbit-button {
  width: 5rem;
  align-items: right;
  float: right;
  position: relative;
  background-color: var(--navbar-button-color);
  color: white;
  cursor: pointer;
  padding: 0.25rem 0.25rem;
  height: 1.55rem;
  border-radius: 1rem;
}
.modal-form__cancel-button {
  width: 5rem;
  align-items: right;
  float: right;
  position: relative;
  color: 0000;
  cursor: pointer;
  padding: 0.25rem 0.25rem;
  height: 1.55rem;
  border-radius: 1rem;
}
.modal-form__input {
  background-color: var(--modal-background-color);
  border-bottom: 1px solid var(--label-text-color);
  color: var(--normal-font-color);
  height: 2rem;
  /* padding: 0.3rem; */
  font-size: 1rem;
}
.modal-form__input_properties {
  background-color: #191919 !important;
  border-bottom: 1px solid var(--label-text-color);
  color: black;
  height: 2rem;
  /* padding: 0.3rem; */
  font-size: 1rem;
}
html body div#modal div#main-modal div.modal-container div.modal-content form.modal-form div.modal-form__input-section div div.modal-form__input_properties.css-b62m3t-container div.css-13cymwt-control{
  background-color: #191919;
}
html body div#modal div#main-modal div.modal-container div.modal-content form.modal-form div.modal-form__input-section div div.modal-form__input_properties.css-b62m3t-container div.css-t3ipsp-control{
  background-color: #191919;
}
html body div#modal div#main-modal div.modal-container div.modal-content form.modal-form div.modal-form__input-section div div.modal-form__input_properties.css-b62m3t-container{
  background-color: #191919;
}
/* #react-select-2-listbox{
  background-color: #191919;
} */
.modal-form__input:-webkit-autofill:focus,
.modal-form__input:-webkit-autofill:hover,
.modal-form__input:-webkit-autofill {
  -webkit-text-fill-color: var(--normal-font-color);
  font-size: 1rem;
  box-shadow: 0 0 0px 1000px var(--modal-background-color) inset;
  caret-color: #ffff;
  transition: var(--modal-background-color) 5000s ease-in-out 0s;
}
.modal-form__input:focus {
  border-bottom: 1px solid var(--navbar-button-color);
}
.modal-form__label-text {
  color: var(--label-text-color);
  font-size: 0.9rem;
}
.modal-form__input-section {
  padding: 1rem;
  width: 50%;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
}

/* .modal-form__input-section--message {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
} */

.modal-form__submit-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
#delete-modal .modal-content {
  width: 33rem;
  background-color: var(--modal-background-color);
  color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: var(--modal-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 9rem;
}
#delete-modal .modal-alert-info {
  text-align: center;
}
#delete-modal .modal-form__user-detail {
  display: flex;
  width: 50%;
  margin: auto;
}
#delete-modal .modal-form__sumbit-buttons {
  justify-content: center;
  justify-content: space-between;
  width: 14rem;
  margin: auto;
}
#delete-modal .modal_form_cancel_button {
  width: 5rem;
  align-items: right;
  float: right;
  background-color: var(--navbar-button-color);
  color: white;
  cursor: pointer;
  /* margin-left: 23rem; */
  padding: 0.25rem 0.25rem;
  border-radius: 0.3rem;
}
/* #delete-modal .modal-form__cancel-button {
  padding: 0.25rem 0.25rem;
} */
#logout-modal .modal-content {
  width: 26rem;
  background-color: var(--modal-background-color);
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: var(--modal-border-radius);
}
#delete-modal .modal-content {
  width: 33rem;
  background-color: var(--modal-background-color);
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: var(--modal-border-radius);
}
/* #delete-modal .modal-form__cancel-button {
  border-radius: 0.3rem;
  width: 5rem;
} */
#delete-modal .modal-form__submit-buttons {
  justify-content: space-between;
  width: 11rem;
  margin: auto;
}
.delete-modal-form__cancel-button,
.delete-modal-form__sumbit-button {
  padding: 0.25rem;
  border-radius: 3px;
  width: 5rem;
  cursor: pointer;
}
.delete-modal-form__sumbit-button {
  background-color: var(--add-functionality-button-border);
  color: white;
}

#logout-modal .logout-selection-button {
  display: flex;
  width: 15rem;
  margin: auto;
  padding: 0.25rem 0.25rem;
  justify-content: space-around;
  margin-top: 1rem;
}
#logout-modal .cancel-button,
#logout-modal .regular-button {
  padding: 0.25rem 0.25rem;
  width: 5rem;
  border-radius: 3px;
  cursor: pointer;
}
#logout-modal .regular-button {
  background-color: var(--add-functionality-button-border);
  color: white;
}
#change-password-modal .modal-content {
  width: 33rem;
  background-color: var(--modal-background-color);
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: var(--modal-border-radius);
}
#change-password-modal .modal-form__input-section {
  width: 100%;
}
#change-password-modal #user_password {
  width: 100%;
}
@media (max-width: 600px) {
  .modal-form__input-section {
    padding: 0.5rem;
    width: 100%;
  }
  #main-modal .modal-content {
    width: 90%;
    top: 50%;
    max-height: 90%;
    overflow-y: scroll;
  }

  #delete-modal .modal-content {
    width: 21rem;
  }
  .modal-close-icon {
    top: 0px;
  }
  .modal-form__label-text {
    font-size: 0.7rem;
  }
  .modal-form__input {
    font-size: 0.8rem;
  }
  .modal-container .modal-title {
    font-size: 1rem;
  }
  #change-password-modal .modal-content {
    width: 22rem;
  }
  #main-modal .modal-form__input-section-checkbox {
    width: 8rem;
  }

  #logout-modal .modal-content {
    width: 20rem;
  }
  .modal-form__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}
