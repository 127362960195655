.property-list-table {
  margin-top: 1rem;
  height: calc(100% - 3rem);
}
.property-list-page {
  background-color: #191919;
  text-align: center;
  margin-top: 70px;
  width: 88%;
  margin: 0rem auto;
  height: 100%;
}
.property-list-table__accordion {
  display: flex;
}
.property-list-table__accordion {
  color: white;
  cursor: pointer;
}
.property-list-table__accordion--display {
  margin: auto;
  padding: 1rem 9.5rem;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  background-color: #020202;
  text-align: left;
}
.property-list-table__accordion--none {
  display: none;
}
.property-list-table__category-title {
  background-color: #393939;
  color: #b0b0b0;
  margin-top: 17px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 2fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.5rem 1rem;
  grid-column-gap: 1rem;
  text-align: left;
  font-style: italic;
}
.property-list-table__property-info {
  color: #f9f9f9;
  display: grid;
  grid-template-columns: 0.5fr 1fr 2fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.5rem 1rem;
  grid-column-gap: 1rem;
  text-align: left;
  cursor: pointer;
  margin-top: 0.5rem;
}
.property-list-table__property-info:hover {
  background-color: #282a2e;
}

.list-data--highlight-background {
  background-color: #282a2e;
}
.category-title {
  font-style: italic;
  font-size: 0.9rem;
  color: var(--label-text-color);
}
.list-table {
  height: calc(100% - 2rem);
  overflow: auto;
}
.category-info {
  margin: 0.2rem 0;
}
.selection-button {
  padding: 0.3rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--add-functionality-button-border);
  background-color: #191919;
  border: 1px solid var(--add-functionality-button-border);
  border-radius: 1rem;
}
.selection-icon {
  margin-right: 10px;
}
.selection-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: auto;
}
.selection-button {
  margin: 0 1rem;
}
.list-data--mobile {
  display: none;
}
.list-data--highlight-background--mobile {
  display: none;
}
@media (max-width: 1024px) {
  .list-table {
    height: calc(100% - 0rem);
  }
  .list-data--desktop {
    display: none;
  }
  .list-data--mobile {
    display: block;
  }
  .list-data--highlight-background {
    display: none;
  }
  .property-list-table__category-title {
    display: none;
  }
  .list-data--highlight-background--mobile {
    display: block;
    border-radius: var(--modal-border-radius) var(--modal-border-radius) 0 0;
  }
  .property-list-table__property-info {
    color: white;
    display: block;
    text-align: left;
    background-color: #282a2e;
    padding: 1rem 0.5rem;
    margin: 1rem 0 0rem 0;
    border-radius: var(--modal-border-radius) var(--modal-border-radius) var(--modal-border-radius) var(--modal-border-radius);
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem;
    grid-gap: 1rem;
    position: relative;
    padding-bottom: 4rem;
    grid-template-columns: 0.8fr 0.9fr;
  }
  .selection-buttons {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  .property-list-table__property-info--selected {
    color: white;
    display: block;
    text-align: left;
    background-color: #282a2e;
    cursor: pointer;
    padding: 1rem 0.5rem;
    margin: 1rem 0 0rem 0;
    border-radius: var(--modal-border-radius) var(--modal-border-radius) 0 0;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding: 1rem 1rem;
    grid-gap: 1rem;
    padding-bottom: 4rem;
    grid-template-columns: 0.8fr 0.9fr;
  }
  .property-list-property-info__property-name,
  .property-list-table__user-info__property-address {
    width: 100%;
  }

  .property-list-table__user-info__install-date,
  .property-list-table__user-info__warranty-date {
    width: 45%;
  }
  .text-label {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: var(--label-text-color);
  }
  .property-list-table__accordion--display {
    padding: 1rem;
    grid-template-columns: 1fr 1fr;
    border-radius: 0 0 var(--modal-border-radius) var(--modal-border-radius);
  }
  .selection-button {
    margin: 0 0.25rem;
  }
  .property-list-table__accordion--display {
    display: flex;
    flex-wrap: wrap;
  }
  .contact-person,
  .contact_number {
    width: 45%;
  }
  .contact_email {
    width: 100%;
  }
  .mac_address {
    width: 45%;
  }
}
@media (max-width: 600px) {
  .property-list-table__accordion--display {
    display: flex;
    flex-wrap: wrap;
  }

  .property-list-text {
    font-size: 1.2rem;
    color: var(--normal-font-color);
  }
  .contact-person,
  .contact_number {
    width: 45%;
  }
  .contact_email {
    width: 100%;
  }
  .mac_address {
    width: 45%;
  }
  .text-label {
    font-size: 0.7rem;
  }

} ;
