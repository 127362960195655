.home-page {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: linear-gradient(rgba(51, 46, 46, 0.5), rgba(31, 30, 30, 0.5)),
    url("../../../assets/images/components/background-image.png");
  background-size: 100% 100%;
  background-color: black;
}
.home-page__header {
  width: calc(100% - 70px);
  position: absolute;
  right: 0;
  height: 70px;
  background-color: rgb(5, 5, 5);
}
.home-page__sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 70px;
  background-color: rgb(5, 5, 5);
}
.home-page__info-container {
  width: calc(100% - 70px);
  position: absolute;
  right: 0;
  height: calc(100% - 70px);
  top: 70px;
  background-color: #191919;
}
@media (max-width: 600px) {
  .homepage__sidebar {
    width: 50px;
  }
  .home-page__header {
    width: calc(100% - 50px);
    height: 50px;
  }
  .home-page__info-container {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    top: 50px;
    overflow: scroll;
  }
  .header__niurix-logo {
    width: 5rem;
  }
  .admin-logout-button {
    width: 7rem;
    /* position: fixed; */
  }
  .sidebar__icon__hambergur {
    margin-top: 0.5rem;
    width: 2.5rem;
  }
  .sidebar__icon__close-icon {
    margin-top: 1.5rem;
  }

  .add-user-button {
    padding: 0.3rem 0.5rem;
    margin-left: 38px;
    font-size: 0.7rem;
  }

  .property-list-table__category-title {
    font-size: 14px;
    display: none;
    padding: 0.5rem 0.5rem;
  }
  .property-list-table__property-info {
    background-color: #282a2e;
  }
  .user-list-table__category-title {
    display: none;
  }
  .alignitems {
    justify-content: space-between;
  }
  .home-page__sidebar {
    width: 50px;
  }
  .sidebar__icon__close-icon {
    margin-bottom: 3.5rem;
    margin-left: 1rem;
  }
  .sidebar__icon svg {
    left: 8px;
    width: 2rem;
  }
  .sidebar__icon span {
    padding-left: 3.5rem;
    font-size: 1rem;
  }
  .add-user-button {
    font-size: 0.7rem;
  }
}
