.user-list-page {
  /* background-color: #191919; */

  text-align: center;
  margin-top: 70px;
  width: 88%;
  margin: 0rem auto;
  height: 100%;
}
.alignitems {
  display: flex;
  align-items: center;
}
.userlist-text {
  color: var(--normal-font-color);
  font-size: 2rem;
}
.add-user-button {
  border: 1px solid var(--add-functionality-button-border);
  padding: 0.3rem 0.8rem;
  background-color: var(--modal-border-radius);
  color: white;
  cursor: pointer;
  margin-left: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  font-size: 0.9rem;
  color: var(--add-functionality-button-border);
}
.add-icon {
  margin-right: 9px;
}
.user-list-table {
  height: calc(100% - 4rem);
}
.user-list-table__user-info {
  display: flex;
  color: var(--normal-font-color);
  justify-content: space-between;
  padding: 1rem;
  margin-top: 0.5rem;
}

.user-list-table__category-title {
  background-color: #393939;
  color: #b0b0b0;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 2fr 1fr;
  align-items: center;
  padding: 0.5rem 1rem;
  grid-column-gap: 1rem;
  text-align: left;
  margin-top: 17px;
  font-style: italic;
}
.selection-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: auto;
  /* margin-left: 5rem; */
}

.user-list-table__user-info {
  color: #f9f9f9;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 2fr 1fr;
  align-items: center;
  padding: 0.5rem 1rem;
  grid-column-gap: 1rem;
  text-align: left;
}
.user-list-table__user-info--mobile {
  display: none;
}

@media (max-width: 1024px) {
  .user-list-table {
    margin-top: 1rem;
    height: calc(100% - 3rem);
  }

  .user-list-table__category-title {
    display: none;
  }
  .userlist-text {
    font-size: 1.5rem;
  }
  .user-list-table__user-info {
    display: none;
  }
  .user-list-table__user-info--mobile {
    color: white;
    display: block;
    text-align: left;
    background-color: #282a2e;
    padding: 1rem 1rem;
    padding-bottom: 4rem;
    position: relative;
    margin: 1rem 0 1rem 0;
    border-radius: var(--modal-border-radius);
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .user-list-table__user-info__first-name,
  .user-list-table__user-info__last-name {
    width: 45%;
  }
  .user-list-table__user-info__user-name {
    width: 100%;
  }
  .text-label {
    font-size: 0.7rem;
    color: var(--label-text-color);
  }
  .selection-button {
    margin: 0 0.25rem;
  }
  .empty-selection-button{
    display: flex;
    justify-content: space-between;
    
  }
}
@media (max-width: 600px) {
  .userlist-text {
    font-size: 1.2rem;
  }
}
