.login-form {
  flex-direction: column;
  margin-top: 45px;
  width: 31rem;
  padding: 4rem 5rem;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: black;
}
.login-form > div:nth-child(2) {
  margin-top: 28px;
}
.login-form__login-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.login-form__login-button {
  border-radius: 1rem;
  background-color: #1e65e0;
  color: white;
  cursor: pointer;
  position: relative;
  width: 90px;
  height: 2rem;
}

.login-form__user-input {
  background-color: black;
  border-bottom: 1px solid var(--label-text-color);
  color: #ffff;
  width: 100%;
  height: 2rem;
  position: relative;
  font-size: 1.1rem;
}
.login-form__user-input:focus {
  border-bottom: 1px solid #1e65e0;
}
.login-form__user-input:-webkit-autofill:focus,
.login-form__user-input:-webkit-autofill {
  -webkit-text-fill-color: var(--normal-font-color);
  font-size: 1rem;
  box-shadow: 0 0 0px 1000px #000 inset;
  caret-color: #ffff;
  transition: background-color 5000s ease-in-out 0s;
}
.login-form__label {
  font-size: 0.9rem;
  color: var(--label-text-color);
}
.login-form__label:focus {
  color: #1e65e0;
}
.login-form__reset-password-link {
  font-size: 0.8rem;
  color: #1e65e0;
}
.password-container {
  position: relative;
}
.login-form__password-icon {
  position: absolute;
  width: 1.25rem;
  right: 0.5rem;
  height: 20px;
  top: 0.25rem;
  cursor: pointer;
}
